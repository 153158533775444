import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import './index-top.css'
import Banner from '../components/banner'
import LogoMain from '../components/logo-main'


const IndexTop = ({ illustMain, banner }) => {
  return (
    <div className='index-top-wrapper'>
      {/*<Link to="/" className='logo-container'>
        <div className='logo-symbol'></div>
        <div className='logo-text'></div>
      </Link>*/}
      {/*<Link
        to='/'
        className='logo-container'
      >
        <div className="logo-main-container">
          <img src={logoMain} alt="logo"/>
        </div>
        <div className="logo-main-text-container">
          <img src={logoMainText} alt="logo"/>
        </div>
      </Link>*/}
      <LogoMain />

      {/* <Banner
        banner={banner}
      /> */}

      <div className='index-top-container'>
        <div className='index-top-left'>
          <div className='index-top-title'>
            {/* Connecting People, Knowledge & Experience */}
            디자인 스펙트럼
          </div>
          <div className='index-top-subtitle'>
            사람과 사람을 연결하고, 지식과 경험을 연결하여 지속가능한 디자인 커뮤니티를 만듭니다.
          </div>
        </div>
        <div className='index-top-illust'>
          <Img fluid={{
            ...illustMain.fluid
          }} />
        </div>
      </div>
    </div>
  );
}

export default IndexTop
