import React from 'react'
import { Link } from 'gatsby'
import './sns.css'

import sns1 from '../static/svgs/ic-facebook.svg'
import sns2 from '../static/svgs/ic-discord.svg'
import sns3 from '../static/svgs/ic-youtube.svg'
import sns4 from '../static/svgs/ic-instagram.svg'
import sns5 from '../static/svgs/ic-podcast.svg'
import sns6 from '../static/svgs/ic-email.svg'




const SNS = () => {
  return (
    <div className='sns-container'>
        <div className='sns-text'>다양한 SNS를 통해 디자인 스펙트럼의 소식을 만날 수 있습니다.</div>
        
        <div className='sns-list'>
            <a className='sns-item sns-facebook' href="https://www.facebook.com/sharedesignspectrum/" target="_blank" rel="noopener noreferrer">
                <img src={sns1} alt="Facebook"/>
                <span>Facebook</span>
            </a>

            <a className='sns-item sns-discord' href="https://discord.gg/vKt7nMgqCb" target="_blank" rel="noopener noreferrer">
                <img src={sns2} alt="Discord"/>
                Discord
            </a>

            <a className='sns-item sns-youtube' href="https://www.youtube.com/channel/UCu2_SsmtFAcDIuAA9H-lshA" target="_blank" rel="noopener noreferrer">
                <img src={sns3} alt="YouTube"/>
                YouTube
            </a>

            <a className='sns-item sns-facebook' href="https://www.instagram.com/designspectrum_official" target="_blank" rel="noopener noreferrer">
                <img src={sns4} alt="Instagram"/>
                Instagram
            </a>

            <a className='sns-item sns-podcast' href="https://www.podbbang.com/ch/13590" target="_blank" rel="noopener noreferrer">
                <img src={sns5} alt="Podcast"/>
                Podcast
            </a>

            <a className='sns-item sns-email' href="mailto:contact@designspectrum.org">
                <img src={sns6} alt="Email"/>
                Email Us
            </a>

            <div className='sns-item'></div>
        </div>

    </div>
  );
}

export default SNS
